// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@include mat-core();

@import '~bootstrap/dist/css/bootstrap.min.css';


//// Variables
@import './variables.scss';

html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto,
        "Helvetica Neue", sans-serif;
    background         : url('./assets/background.jpg');
    background-position: left;
    background-color   : #000;
    background-repeat  : no-repeat;
}

.select {
    cursor: pointer;
}

.input-group-text {
    background-color: #fff;
    border-radius   : 0;
}

.form-control:focus {
    border-color      : #cccccc;
    -webkit-box-shadow: none;
    border-bottom     : 2px solid var(--primary);
}

.mat-drawer-content {
    display       : flex !important;
    flex-direction: column;
    overflow      : hidden;
}
.mat-card-header-text{
    width: 100%;
}
.mat-progress-bar-fill::after {
    background-color: var(--primary);
}

.mat-progress-bar-buffer {
    background-color: #e7e6e8f5;
}

.mat-button-toggle-checked {
    background-color: var(--primary);
    color           : #fff;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ffffff00;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color:  var(--primary)!important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #08aa558a!important;
}
.mat-tab-labels {
    display: none !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--primary)!important;
}

///////ALERT
.alert {
    font-weight: 500;
    color      : #fff;
    border     : 0;
}

.alert-success {
    background: var(--success);
}

.alert-error {
    background: var(--error);
}

.alert-warning {
    background: var(--warning);
}

.mat-simple-snackbar-action button {
    color: #fff;
}